<!--任务配比-->
<template>
    <div class="taskRadioInfo">
        <table>
            <tr>
                <th class="title" colspan="6">
                    任务配比
                </th>
            </tr>
            <tr>
                <th>
                    <div class="cell">
                        工程名称：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ radioData.station_name }}
                    </div>
                </td>
                <th>
                    <div class="cell">
                        施工单位：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ radioData.sgdw_name }}
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <div class="cell">
                        生产日期：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ radioData.product_time }}
                    </div>
                </td>
                <th>
                    <div class="cell">
                        实际生产数量：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ radioData.grand_total_complete_quantity }}
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <div class="cell">
                        强度等级：
                    </div>
                </th>
                <td colspan="5">
                    <div class="cell">
                        {{ radioData.strength_grade_name }}
                    </div>
                </td>
            </tr>
        </table>
        <div class="line"></div>
        <table>
            <tr>
                <td colspan="6">
                    <div class="cell line-cell">
                        <span>生产机组：</span>
                        <div
                            :class="['line-item',lineSelected==name?'active':'']"
                            v-for="name in radioData.line_names"
                            :key="name"
                            @click="lineChange(name)"
                        >
                            {{ name }}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <div class="cell">
                        <span>配比类型：</span>
                        <el-radio-group class="radio-group-custom" v-model="lineRadio" @change="lineTypeChange">
                            <div class="raido-list">
                                <el-radio :label="1">
                                    砼配比
                                </el-radio>
                                <el-radio :label="2">
                                    砂浆配比
                                </el-radio>
                            </div>
                        </el-radio-group>
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <div class="cell">
                        试验配比编号：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ curLineData.line_experiment_formula_number }}
                    </div>
                </td>
                <th>
                    <div class="cell">
                        施工配比编号：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ curLineData.product_formula_number }}
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <div class="cell">
                        水泥仓号：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ curLineData.cement_warehouse_no }}
                    </div>
                </td>
                <th>
                    <div class="cell">
                        外加剂仓号：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ curLineData.admixture_warehouse_no }}
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    <div class="cell">
                        水泥厂家：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ curLineData.cement_manufacturer }}
                    </div>
                </td>
                <th>
                    <div class="cell">
                        外加剂厂家：
                    </div>
                </th>
                <td colspan="2">
                    <div class="cell">
                        {{ curLineData.admixture_manufacturer }}
                    </div>
                </td>
            </tr>
            <tr>
                <th class="color1 cailiaoname">
                    <div class="title-cell">
                        材料名称：
                    </div>
                </th>
                <td class="cl-box" rowspan="3" colspan="5">
                    <div class="cl-list">
                        <table class="cl-table">
                            <tr>
                                <td v-for="(item,index) in curLineData.material_data" :key="'name'+index">
                                    <div class="title-cell">
                                        <!--                                        {{ item.name }}-->
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="item.name"
                                            placement="top"
                                        >
                                            <div class="item-name">
                                                {{ item.name }}
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in curLineData.material_data" :key="'quantity'+index">
                                    {{ item.product_quantity }}
                                </td>
                            </tr>
                            <tr>
                                <td v-for="(item,index) in curLineData.material_data" :key="'consume'+index">
                                    {{ item.consume }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr>
                <th class="color1">
                    配合比：
                </th>
            </tr>
            <tr>
                <th class="color1">
                    单方消耗：
                </th>
            </tr>
            <tr>
                <th><div class="cell"></div>备注：</th>
                <td colspan="5">
                    <div class="cell">
                        {{ curLineData.remarks }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'task-radio-info',
    data() {
        return {
            task_number: '',
            lineSelected: '',
            lineRadio: 1,
            radioData: {},
            curLine: {},
            curLineData: {},
        };
    },
    props: {
        extr: {
            type: Object,
        },
    },
    created() {
        this.task_number = this.extr.task_number;
        this.getData();
    },

    methods: {
        getData() {
            this.$axios
                .get('/interfaceApi/production/productorder/product_task_formula/' + this.task_number)
                .then(data => {
                    if (data.line_data[0]) {
                        this.curLine = data.line_data[0];
                        this.lineRadio = 1;
                        this.lineSelected = data.line_data[0].line_name;
                        this.curLineData = data.line_data[0].concrete || [];
                    }
                    this.radioData = data;
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        /**
         * 点击选中变化
         * @param name
         * @param type
         */
        lineChange(name) {
            this.curLine = this.radioData.line_data.filter(item => item.line_name === name)[0];
            this.curLineData = this.curLine.concrete;
            if (this.lineRadio === 2) {
                this.curLineData = this.curLine.mortar;// 砂浆配比
            }
            this.lineSelected = this.curLine.line_name;
        },
        lineTypeChange(val) {
            if (val === 2) {
                this.curLineData = this.curLine.mortar;// 砂浆配比
            } else {
                this.curLineData = this.curLine.concrete;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
    .taskRadioInfo {
        padding 0.19rem;
        .line{
            width: 100%;
            height: 0.06rem;
            background: #EEF0F7;
        }
        table{
            width: 100%;
            table-layout fixed
            border-collapse collapse
            border:none;
            word-wrap: break-word;
            word-break: break-all;
            .title{
                height: 0.6rem;
                background: #EDF0F5;
                border: 1px solid #D7D7D7;
                text-align center
            }
            th,td{
                padding 0.1rem;
                border 0.01rem solid #D7D7D7
                border-collapse collapse
                font-size 0.16rem
            }
            th{
                height: 0.6rem;
                background: #F6F8FC;
                border: 1px solid #D7D7D7;
                color #022782
                text-align: left;
                padding-left: 0.3rem;
                font-weight: 400;

            }
            td{
                color #333333
            }
            .cl-box{
                padding 0;
            }
            .cailiaoname{
                padding: 0.199rem;
                padding-left 0.3rem;
            }
            .title-cell{
                height 0.6rem;
                .item-name{
                    width 100%;
                    height 100%;
                    overflow hidden
                    text-overflow ellipsis
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            .line-cell{
                display flex;
                align-items center;
                .line-item{
                    padding: 0.03rem 0.1rem;
                    border: 1px solid #eee;
                    border-radius: 0.03rem;
                    color: #333;
                    margin-right: 0.2rem;
                    &.active{
                        border: 1px solid #409eff;
                        color: #409eff;
                    }
                }
            }
            .cl-list{
                width 100%;
                overflow-x auto;
                .cl-table{
                    min-width 100%;
                    td{
                        padding 0.2rem;
                    }
                }
            }
            .color1{
                background: #F5F2ED;
                border: 1px solid #D7D7D7;
                color #823802
            }
            .radio-box{
                margin 0 0.1rem;
                width: 1.35rem;
                height: 0.6rem;
                position: relative;
                border: 1px solid #eee;
                border-radius: 0.03rem;
                &.active{
                    border 1px solid #409eff
                    span{
                        color #409eff
                    }
                }
                .radio-group-custom{
                    height 100%;
                }
                span{
                    position: absolute;
                    background: #fff;
                    top: -0.11rem;
                    left: 0.05rem;
                    padding: 0 0.04rem;
                }
                .raido-list{
                    height: 100%;
                    padding-left: 0.25rem;
                    padding-top: 0.1rem;
                    padding-bottom: 0.03rem;
                    display flex
                    flex-direction column
                    justify-content space-around
                }
            }
        }
    }

</style>
